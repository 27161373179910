import {
    AttributeColormapAssociation,
    ColormapResponseList,
    ContinuousColormapResponse,
} from '@local/api-clients/dist/colormap/enhancedColormapClient';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { AttributeNameColormapsMapType, QueryObject } from './types';

const dataKey = 'data';
const getColormapCollection = 'getColormapCollection';
const getAssociationCollection = 'getAssociationCollection';

const colormapState = (state: RootState) => state.colormap;

function getWorkspaceContinuousColormaps() {
    return createSelector(colormapState, (stateRoot) => {
        const colormaps: ContinuousColormapResponse[] = [];
        Object.values(stateRoot.queries).forEach((value) => {
            const queryValue = value as QueryObject;
            if (queryValue.endpointName === getColormapCollection) {
                const data = queryValue[dataKey];
                if (data?.colormaps.length) {
                    (data as ColormapResponseList).colormaps.forEach((colormap) => {
                        if ('gradient_controls' in colormap) {
                            colormaps.push(colormap);
                        }
                    });
                }
            }
        });
        return colormaps;
    });
}

function getAssociationCollectionByObjectId(objectId: string) {
    return createSelector(colormapState, (stateRoot) => {
        const associations: AttributeColormapAssociation[] = [];
        Object.values(stateRoot.queries).forEach((value) => {
            const queryValue = value as QueryObject;
            if (
                queryValue.endpointName === getAssociationCollection &&
                queryValue.originalArgs.objectId === objectId
            ) {
                const data = queryValue[dataKey];
                if (data?.associations.length) {
                    associations.push(...data.associations);
                }
            }
        });
        return associations;
    });
}

export function getAttributeNameColormapsMap(objectId: string) {
    return createSelector(
        getWorkspaceContinuousColormaps(),
        getAssociationCollectionByObjectId(objectId),
        (colormaps, associations) => {
            const attributeNameColormapsMap: AttributeNameColormapsMapType = {};

            associations.forEach((association) => {
                const {
                    attribute_name: attributeName,
                    colormap_id: colormapId,
                    path,
                    modified_at: modifiedAt,
                } = association;

                const timestamp = new Date(modifiedAt).getTime();

                const currentColormap = colormaps.find((colormap) => colormap.id === colormapId);
                if (currentColormap) {
                    if (!(attributeName in attributeNameColormapsMap)) {
                        attributeNameColormapsMap[attributeName] = [
                            { path, colormap: currentColormap, timestamp },
                        ];
                    } else {
                        attributeNameColormapsMap[attributeName].push({
                            path,
                            colormap: currentColormap,
                            timestamp,
                        });
                    }
                } else {
                    console.error(
                        `Colormap with id ${colormapId} not found in workspace colormaps`,
                    );
                }
            });

            Object.keys(attributeNameColormapsMap).forEach((attributeName) => {
                const colors = attributeNameColormapsMap[attributeName];
                colors.sort((a, b) => b.timestamp - a.timestamp);
            });

            return attributeNameColormapsMap;
        },
    );
}
